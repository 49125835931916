<template>
  <div class="users-corbeille mt-3">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="header-user">
        <v-row>
          <v-col cols="6">
            <!-- <div v-if="getUserLoading" class="color-crm font-sz-12 font-text">
             {{ $t('loading') }}
            </div> -->
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-text-field
              v-model="full_name"
              @input="filter"
              clearable
              :prepend-inner-icon="'mdi-magnify'"
              :label="$t('search')"
              outlined
              :placeholder="$t('search')"
              :persistent-placeholder="true"
              dense
              hide-details
              color="#5C2DD3"
            ></v-text-field
          ></v-col>
        </v-row>
      </div>
      <div class="table-users mt-2">
        <v-data-table
          v-model="selectedTable"
          :headers="$i18n.locale == 'fr' ? computedFields : computedFieldsEn"
          :items="getUsersCorbeille"
          :page.sync="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          :server-items-length="usersCorbeilleCount"
          @update:page="handlePaginationChange"
          @update:items-per-page="changePerPage"
          :footer-props="{
            'items-per-page-options': perPageList
          }"
          class="table-users"
          disable-sort
          :calculate-widths="false"
          :fixed-header="true"
          hide-action
          :no-data-text="
            getUserLoading
              ? $i18n.locale == 'fr'
                ? 'Chargement... Veuillez patienter'
                : 'Loading... Please wait'
              : $i18n.locale == 'fr'
              ? `Il n'y a aucune donnée à afficher dans ce tableau.`
              : 'There is no data to display in this table.'
          "
        >
          <!-- HEADERS TABLE -->
          <!-- <template v-slot:[`header`]="{ props }">
            <tr class="header-users">
              <th
                v-for="header in props.headers"
                :key="header.text"
                :style="{ width: header.width, padding: header.padding }"
              >
                <div
                  :class="{
                    'd-flex justify-content-center align-items-center':
                      header.sortable === true
                  }"
                >
                  <span
                    :style="{
                      width: header.value === 'actions' ? header.width : 'auto'
                    }"
                  >
                    {{ header.text }}</span
                  > -->

          <!-- <font-awesome-icon
                  v-if="header.sortable === true"
                  icon="arrow-up"
                  :class="
                    header.value === sort_by
                      ? 'style-arrow-selected'
                      : 'style-arrow-not-selected'
                  "
                />
                <font-awesome-icon
                  v-if="header.sortable === true"
                  icon="arrow-down"
                  :class="
                    header.value === sort_by
                      ? 'style-arrow-selected'
                      : 'style-arrow-not-selected'
                  "
                /> -->
          <!-- </div>
              </th>
            </tr>
          </template> -->
          <!-- BODY TABLE -->

          <template v-slot:[`item.first_name`]="{ item }">
            <td class="custom-cell">
              <div>
                {{ item.full_name }}
              </div>
            </td>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <td class="custom-cell">
              {{ item.email }}
            </td>
          </template>

          <template v-slot:[`item.code_client`]="{ item }">
            <td class="custom-cell">
              {{ item.code_client }}
            </td>
          </template>
          <template v-slot:[`item.societe`]="{ item }">
            <td class="custom-cell">
              {{ item.societe }}
            </td>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <td class="custom-cell">
              {{ item.type | typeFormat }}
            </td>
          </template>
          <template v-slot:[`item.team_name`]="{ item }">
            <td class="custom-cell">
              <div v-if="item.team" class="font-div">
                <span>
                  {{ item.team.data.name }}
                </span>
                <br />
                <span class="color-crm font-sz-12 bold-500">
                  ( {{ item.team.data.type }} )
                </span>
                <span
                  class="color-crm font-sz-12 bold-500"
                  v-if="item.depot_id != null && item.team"
                >
                  - ( {{ item.depot.data.name }} )
                </span>
              </div>
            </td>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <td class="custom-cell">
              {{ item ? item.created_at.split(',')[0] : '' }}
            </td>
          </template>
          <template v-slot:[`item.created_by`]="{}">
            <td class="custom-cell">
              {{ ' - ' }}
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td class="custom-cell">
              <div class="actions"></div>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#5C2DD3" dark v-bind="attrs" v-on="on" icon>
                    <span class="font-sz-20 mb-2"> ...</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click.prevent="handleClickRestaurer(item)"
                    v-if="
                      (currentUser && currentUser.isSuper) || computedRegieCom
                    "
                  >
                    <v-list-item-title>{{
                      $t('btnRestore')
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      (currentUser && currentUser.isSuper) || computedRegieCom
                    "
                    @click.prevent="handleClickDeleteDefinitivement(item)"
                  >
                    <v-list-item-title>
                      {{ $t('btnDeleteDefinitive') }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
      </div>
      <!-- <div class="footer-style-table">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            :total-visible="7"
            color="#5C2DD3"
            item-color="#5C2DD3"
            circle
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            class="per-page-element-style"
            v-model="per_page"
            :items="perPageList"
            @change="changePerPage"
            label="Eléments par page"
            outlined
            dense
            hide-details
            no-data-text="Aucun élément trouvé"
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-select>
        </div>
      </div> -->
    </div>
    <!-- Supprimer Définitivement User Modal -->
    <v-dialog
      v-model="userDeletDefinitementModal"
      max-width="500"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Supprimer définitivement un utilisateur'
                : 'Permanently deleting a user'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('userDeletDefinitementModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? "l'utilisateur" : 'the user'
              })
            }}
            <strong class="name">
              {{ userToDelete ? userToDelete.first_name : '' }}
              {{ userToDelete ? userToDelete.last_name : '' }}</strong
            >
            ?
          </p>
          <div class="color-red mt-2 mb-2 flex center">
            <font-awesome-icon
              class="mr-1 img-h-19"
              icon="exclamation-circle"
            />
            <span
              >{{
                $i18n.locale === 'fr'
                  ? 'La suppression sera définitive !'
                  : 'The deletion will be definitive!'
              }}
            </span>
          </div>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSupprimerDefinitivement"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn
            text
            @click="closeDialog('userDeletDefinitementModal')"
            small
            >{{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Supprimer Définitivement User Modal -->
    <!-- Restaurer User Modal -->
    <v-dialog
      v-model="userRestaurerModal"
      max-width="500"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Restaurer un utilisateur'
                : 'Restore a user'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('userRestaurerModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $i18n.locale === 'fr'
                ? 'Êtes-vous sûr de vouloir restaurer cet utilisateur'
                : 'Are you sure you want to restore this user'
            }}
            <strong class="name">
              {{ userToRestaurer ? userToRestaurer.first_name : '' }}
              {{ userToRestaurer ? userToRestaurer.last_name : '' }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleRestaurerUser"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('userRestaurerModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Restaurer User Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')
export default {
  data() {
    return {
      selectedTable: [],
      perPageList: [10, 20, 30, 50],
      fields: [
        { value: 'first_name', text: 'Utilisateur', sortable: false },
        { value: 'email', text: 'E-mail', sortable: false },
        { value: 'type', text: 'Type', sortable: false },
        { value: 'team_name', text: 'Equipe', sortable: false },
        { value: 'created_at', text: 'Créé le', sortable: false },
        { value: 'created_by', text: 'Créé par', sortable: false },
        { value: 'actions', text: 'Actions', sortable: false }
      ],
      page: 1,
      per_page: 10,
      active: 1,
      sort_by: 'first_name',
      sort_by_desc: 'first_name',
      sortDesc: false,
      loading: false,
      initLoading: true,
      error: null,
      userToDelete: null,
      userToRestaurer: null,
      full_name: null,
      userDeletDefinitementModal: false,
      userRestaurerModal: false,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItems: this.usersCorbeilleCount
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchCorbeilleUsers',
      'deleteDefinitivementUser',
      'restaurerUser'
    ]),
    sort(sort) {
      this.sort_by = sort.sortBy
      this.sort_by_desc = sort.sortBy
      if (!sort.sortDesc) {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    },
    changePerPage(newPerPage) {
      this.pagination.itemsPerPage = newPerPage
      this.pagination.page = 1
      this.per_page = newPerPage
      if (!this.sortDesc) {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    },
    handlePaginationChange(paginate) {
      this.page = paginate
      this.pagination.page = paginate
      if (!this.sortDesc) {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    },
    filter() {
      this.page = 1
      if (!this.sortDesc) {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.fetchCorbeilleUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    },
    closeDialog(ref) {
      this[ref] = false
    },
    handleClickRestaurer(user) {
      this.userToRestaurer = user
      this.userRestaurerModal = true
    },
    handleClickDeleteDefinitivement(user) {
      this.userToDelete = user
      this.userDeletDefinitementModal = true
    },
    async handleSupprimerDefinitivement() {
      this.loading = true
      this.error = null
      const response = await this.deleteDefinitivementUser(this.userToDelete)
      if (response.success) {
        this.loading = false
        this.closeDialog('userDeletDefinitementModal')
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    async handleRestaurerUser() {
      this.loading = true
      this.error = null
      const response = await this.restaurerUser(this.userToRestaurer)
      if (response.success) {
        this.loading = false
        this.closeDialog('userRestaurerModal')
      } else {
        this.loading = false
        this.error = response.error
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUsersCorbeille',
      'getUserLoading',
      'usersCorbeilleCount',
      'currentUser'
    ]),
    totalPages() {
      if (this.usersCorbeilleCount) {
        return Math.ceil(this.usersCorbeilleCount / this.per_page)
      }
      return this.usersCorbeilleCount
    },
    computedFields: function() {
      if (this.currentUser && this.currentUser.code_client != null) {
        let fields = [
          { value: 'first_name', text: 'Nom', sortable: false },
          { value: 'email', text: 'E-mail', sortable: false },
          {
            value: 'code_client',
            text: 'Code client',
            sortable: false,
            thClass: 'width-th-code',
            tdClass: 'width-th-code'
          },
          {
            value: 'societe',
            text: 'Société',
            sortable: false,
            thClass: 'width-th',
            tdClass: 'width-th'
          },

          { value: 'created_at', text: 'Créé le', sortable: false },
          { value: 'created_by', text: 'Créé par', sortable: false },
          { value: 'actions', text: '', sortable: false }
        ]
        return fields
      }
      return this.fields
    },
    computedFieldsEn: function() {
      if (this.currentUser && this.currentUser.code_client != null) {
        let fields = [
          {
            value: 'first_name',
            text: 'Name',
            sortable: false
          },
          { value: 'email', text: 'E-mail', sortable: false },
          {
            value: 'code_client',
            text: 'client code',
            sortable: false,
            thClass: 'width-th-code',
            tdClass: 'width-th-code'
          },
          {
            value: 'societe',
            text: 'Company',
            sortable: false,
            thClass: 'width-th',
            tdClass: 'width-th'
          },

          {
            value: 'created_at',
            text: 'Created on',
            sortable: false
          },
          {
            value: 'created_by',
            text: 'Created by',
            sortable: false
          },
          { value: 'actions', text: 'Actions', sortable: false }
        ]
        return fields
      }
      return [
        {
          value: 'first_name',
          text: 'User',
          sortable: false
        },
        { value: 'email', text: 'E-mail', sortable: false },
        { value: 'type', text: 'Type', sortable: false },
        {
          value: 'team_name',
          text: 'Team',
          sortable: false
        },
        {
          value: 'created_at',
          text: 'Created on',
          sortable: false
        },
        {
          value: 'created_by',
          text: 'Created by',
          sortable: false
        },
        { value: 'actions', text: 'Actions', sortable: false }
      ]
    },
    computedRegieCom: function() {
      if (
        this.currentUser &&
        this.currentUser.details &&
        this.currentUser.details.team &&
        this.currentUser.details.team.data &&
        this.currentUser.details.team.data.type === 'regie' &&
        this.currentUser.type == 'user.commercial'
      ) {
        return true
      }
      return false
    }
  },
  async mounted() {
    this.initLoading = true
    await this.fetchCorbeilleUsers({
      page: this.page,
      per_page: this.per_page,
      sort_by: this.sort_by,
      active: this.active,
      exclude: ['team.manager', 'team.depots', 'visibleTeams']
    })
    this.initLoading = false
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'user.admin':
          return 'Utilisateur Admin'
        case 'user.agent':
          return 'Agent'
        case 'user.resp.plan':
          return 'Responsable Planning'
        case 'user.supervisor':
          return 'Régie Superviseur'
        case 'user.commercial':
          return 'Régie Commercial'
        case 'user.final':
          return 'Régie Utilisateur Final'
        case 'user.technicien':
          return 'Filiale Poseur'
        case 'user.administration':
          return 'GE Administration'
        case 'user.client':
          return 'Client'
        case 'user.commercial.ite':
          return 'Filiale Commercial'
        case 'user.sub-super-admin':
          return 'Sous Super Admin'
        default:
          return ''
      }
    },
    date: function(value) {
      if (!value) return ''
      value = moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.users-corbeille {
  .header-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
<style lang="scss">
.users-corbeille {
  .table-users {
    table {
      border-collapse: collapse;
    }

    height: 100% !important;
    max-height: 100% !important;
    .header-users {
      th {
        text-align: left;
        padding: 0px 14px;
        height: 40px;
        font-size: 14px;
        white-space: nowrap;
        border-bottom: 1px solid #e0e0e0;
      }
    }
    thead {
      tr {
        th {
          white-space: nowrap;
          color: rgb(0 0 0) !important;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 12px !important;
          letter-spacing: 0.17px;
          line-height: 17.16px;
          font-family: 'Montserrat', sans-serif;
        }
      }
    }
    .v-data-table__wrapper::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .v-data-table__wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .v-data-table__wrapper::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
    .v-data-table__wrapper {
      cursor: pointer;
      max-height: calc(100vh - 350px) !important;
      height: calc(100vh - 350px) !important;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: auto;
    }
  }
}
</style>
